<script lang="ts">
  import * as Accordion from '@components/ui/accordion';
  import { RichFeatureSection } from '@components/ui/rich-feature-section';
  import { SectionWrapper } from '@components/ui/section-wrapper';
  import { Typography } from '@components/ui/typography';
  import { ApplyNowButton } from '@pages/_components/ApplyNowButton';

  import { type HowToGetStartedData } from './index';

  type $$Props = HowToGetStartedData;

  export let data: $$Props;
</script>

<SectionWrapper id="how-to-get-started" {...$$restProps}>
  <RichFeatureSection class="items-start" variant="default">
    <Typography
      variant="h1"
      size="6xl"
      class="lg:text-pretty lg:max-w-[85%] xl:max-w-[75%] col-span-12 md:col-span-6"
    >
      How to get started
    </Typography>

    <div class="w-full grid grid-cols-1 md:-mt-4 col-span-12 md:col-span-6">
      {#each data.items as { title, content }}
        <Accordion.Root class="w-full py-2  border-b text-[--carbon] border-b-[--carbon]">
          <Accordion.Item value={title}>
            <Accordion.Trigger>
              <Typography variant="h4" size="2xl" class="font-light text-left">
                {title}
              </Typography>
            </Accordion.Trigger>
            <Accordion.Content>
              <Typography variant="span" size="sm" class="font-normal">
                {content}
              </Typography>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
      {/each}

      <ApplyNowButton class="mt-6 sm:mr-auto" />
    </div>
  </RichFeatureSection>
</SectionWrapper>

<style lang="postcss" global>
  :root #how-to-get-started {
    --background: 159 91% 46%;
  }

  :root #how-to-get-started [data-button-root] {
    --primary: 0 0% 13%;
    --primary-foreground: 0 0% 100%;
  }
</style>
